.App {
  width: 100vw;
  height: 100vh;
  background: #000000;
  position: relative;
}

.links-container {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 40px;
  z-index: 1000;
}

.clickable-image {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.clickable-image:hover {
  transform: scale(1.2);
}